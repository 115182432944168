import { type BooleanRequirement, type NumberRequirement, type TextRequirement } from '../types/market-requirements'
import {
  type SearchMarketFilters,
  type SearchFilters,
  type SearchFiltersArray, type SearchFiltersBoolean,
  type SearchFiltersClassificators, type SearchFiltersNumber,
  type SearchFiltersRange, type SearchFiltersString, type SearchSortOrder
} from '../types/search-filters'

export class UrlBuilder {
  private _searchString?: string

  constructor(
    private readonly filters: SearchFilters
  ) {
  }

  public get searchString (): string {
    if (this._searchString != null) return this._searchString
    const params = new URLSearchParams()

    this.storeRange(this.filters, params, 'valueAmount')
    this.storeRange(this.filters, params, 'enquiryPeriod')
    this.storeRange(this.filters, params, 'auctionPeriod')
    this.storeRange(this.filters, params, 'tenderPeriod')
    this.storeRange(this.filters, params, 'qualificationPeriod')
    this.storeRange(this.filters, params, 'publicationDate')
    this.storeRange(this.filters, params, 'valueSum')
    this.storeRange(this.filters, params, 'valueBudget')
    this.storeRange(this.filters, params, 'plansPeriod')
    this.storeRange(this.filters, params, 'plansPublished')

    this.storeArray(this.filters, params, 'status')
    this.storeArray(this.filters, params, 'classification')
    this.storeArray(this.filters, params, 'road')
    this.storeArray(this.filters, params, 'gmdn')
    this.storeArray(this.filters, params, 'inn')
    this.storeArray(this.filters, params, 'ccce_ua')
    this.storeArray(this.filters, params, 'organizer')
    this.storeArray(this.filters, params, 'supplier')
    this.storeArray(this.filters, params, 'region')
    this.storeArray(this.filters, params, 'fundManager')
    this.storeArray(this.filters, params, 'procurementMethodType')
    this.storeArray(this.filters, params, 'funder')
    this.storeArray(this.filters, params, 'market_classification')
    this.storeArray(this.filters, params, 'relatedCategoryId')

    this.storeIntNumber(this.filters, params, 'page')
    this.storeIntNumber(this.filters, params, 'perPage')

    this.storeString(this.filters, params, 'typeSubjectProcurement')
    this.storeString(this.filters, params, 'paymentType')
    this.storeString(this.filters, params, 'keyword')
    this.storeString(this.filters, params, 'planYear')
    this.storeString(this.filters, params, 'relatedProfileIds')

    this.storeBoolean(this.filters, params, 'hasActiveMonitoring')

    this.storeString(this.filters, params, 'sortOrder')

    this.storeMarketFilter(this.filters, params, 'textRequirements')
    this.storeMarketFilter(this.filters, params, 'numberRequirements')
    this.storeMarketFilter(this.filters, params, 'booleanRequirements')

    params.sort()

    this._searchString = params.toString()
    return this._searchString
  }

  private storeRange (filters: SearchFilters, params: URLSearchParams, key: keyof SearchFiltersRange): void {
    const value = filters[key]
    if (typeof value !== 'undefined') {
      if (value.from != null) {
        params.append(key + '.from', value.from)
      }
      if (value.to != null) {
        params.append(key + '.to', value.to)
      }
    }
  }

  private storeArray (
    filters: SearchFilters,
    params: URLSearchParams,
    key: keyof SearchFiltersArray | keyof SearchFiltersClassificators
  ): void {
    const values = filters[key]
    if (typeof values !== 'undefined') {
      values.forEach(
        value => { params.append(key + '[]', value) }
      )
    }
  }

  private storeString (filters: SearchFilters, params: URLSearchParams, key: keyof SearchFiltersString | keyof SearchSortOrder): void {
    const value = filters[key]
    if (typeof value !== 'undefined') {
      params.append(key, value)
    }
  }

  private storeIntNumber (filters: SearchFilters, params: URLSearchParams, key: keyof SearchFiltersNumber): void {
    const value = filters[key]
    if (typeof value !== 'undefined') {
      params.append(key, value.toString())
    }
  }

  private storeBoolean (filters: SearchFilters, params: URLSearchParams, key: keyof SearchFiltersBoolean | keyof SearchSortOrder): void {
    const value = filters[key]
    if (typeof value !== 'undefined') {
      params.append(key, value.toString())
    }
  }

  private storeMarketFilter (
    filters: SearchFilters,
    params: URLSearchParams,
    key: keyof SearchMarketFilters
  ): void {
    const marketFilters = filters[key]
    if (typeof marketFilters !== 'undefined') {
      if (key === 'textRequirements') {
        (marketFilters as TextRequirement[]).forEach((filter: TextRequirement, index: number) => {
          params.append(`textRequirements[${index}][requirement]`, filter.requirement)
          if (filter.values?.length) {
            filter.values.forEach(value => {
              params.append(`textRequirements[${index}][values][]`, value)
            })
          }
          if (filter.value) {
            params.append(`textRequirements[${index}][value]`, filter.value)
          }
        })
      }
      if (key === 'numberRequirements') {
        (marketFilters as NumberRequirement[]).forEach((filter: NumberRequirement, index) => {
          params.append(`numberRequirements[${index}][requirement]`, filter.requirement)
          if (filter.values?.length) {
            filter.values.forEach(value => {
              params.append(`numberRequirements[${index}][values][]`, value.toString())
            })
          }
          if (filter.value) {
            params.append(`numberRequirements[${index}][value]`, filter.value.toString())
          }
          if (filter.minValue) {
            params.append(`numberRequirements[${index}][minValue]`, filter.minValue.toString())
          }
          if (filter.maxValue) {
            params.append(`numberRequirements[${index}][maxValue]`, filter.maxValue.toString())
          }
        })
      }

      if (key === 'booleanRequirements') {
        (marketFilters as BooleanRequirement[]).forEach((filter: BooleanRequirement, index) => {
          params.append(`booleanRequirements[${index}][requirement]`, filter.requirement)
          if (filter.values?.length) {
            filter.values.forEach(value => {
              params.append(`booleanRequirements[${index}][values][]`, value.toString())
            })
          }
          if (filter.value) {
            params.append(`booleanRequirements[${index}][value]`, filter.value.toString())
          }
        })
      }
    }
  }
}
